<template>
  <li
    v-on:click="openBoxChat()"
    v-if="group && group.type != 'customer_service'"
  >
    <img v-bind:src="groupImage" />
    <div class="item-group-chat">
      <h2>{{ truncateString(groupName, 20) }}</h2>
      <!-- <h3>
                <span class="status orange"></span>
                offline

                <span class="status green"></span>
                online
            </h3> -->
      <h3 v-if="group.recentMessage">
        <span
          v-if="group.recentMessage.type == 'text'"
          v-html="
            truncateString(cutElement(group.recentMessage.messageText), 30)
          "
        >
        </span>
        <span v-if="group.recentMessage.type == 'image'">
          Tin nhắn hình ảnh
        </span>
        <span
          v-if="group.recentMessage.type == 'tag-marking'"
          class="item-conversation-tag-marking"
        >
          #Hỏi đáp chấm bài
        </span>
      </h3>
      <span v-if="checkActive()" class="is-notify"></span>
    </div>
  </li>
</template>

<script>
import firebase from "../firebase";
import "firebase/compat/firestore";
const db = firebase.firestore();
export default {
  name: "PageItemConversation",
  data() {
    return {
      groupImage: null,
      groupName: "Guest",
      uid: null,
      chatMembers: undefined,
    };
  },
  props: {
    group: {
      type: Object,
      default: null,
    },
  },
  methods: {
    cutElement(str) {
      let arr = str.split(/\r?\n/);
      if (arr.length > 0) {
        return arr[0];
      }
      return str;
    },
    openBoxChat() {
      this.$emit("openBoxChat", this.group);
    },
    async getInfoUserById(id) {
      let data = null;
      await db
        .collection("users")
        .doc(String(id))
        .get()
        .then(function (doc) {
          if (doc.exists) {
            data = doc.data();
          }
        });
      return data;
    },
    async makeData() {
      this.uid = this.$store.state.prepChatUser.user.uid;
      // this.chatMembers = this.$store.state.prepChatUser.chatMembers
      this.groupName = this.group.name;
      this.groupImage = require("../assets/default-avatar.jpg");

      let arrayKey = Object.keys(await this.group.members);
      if (arrayKey.length > 2) {
        this.groupName = "Nhóm chat";
        this.group.name = "Nhóm chat";
        this.group.photoURL = this.groupImage;
      } else {
        for (let i = 0; i < arrayKey.length; i++) {
          if (arrayKey[i] != String(this.uid)) {
            console.log("friend: " + arrayKey[i]);
            console.log("uid: " + String(this.uid));
            let m = await this.getInfoUserById(arrayKey[i]);
            if (m) {
              this.groupName = m.displayName;
              this.group.name = m.displayName;
              if (m.photoURL) this.groupImage = m.photoURL;
              this.group.photoURL = m.photoURL;
            }
          }
        }
      }
    },
    checkActive() {
      if (this.group.recentMessage) {
        if (!this.group.recentMessage.readBy.includes(this.uid)) {
          return true;
        }
      }
      return false;
    },
    truncateString(string, limit) {
      string.trim();
      if (string.length > limit) {
        return string.substring(0, limit) + "...";
      } else {
        return string;
      }
    },
  },
  created() {
    this.makeData();
  },
};
</script>

<style lang="css" scoped>
.item-group-chat {
  position: relative;
}
.is-notify {
  width: 10px;
  height: 10px;
  background: #6fbced;
  display: block;
  border-radius: 50%;
  position: absolute;
  right: -15px;
  top: 0;
}
.item-conversation-tag-marking {
  padding: 5px;
  background: #ff9f00;
  border-radius: 5px;
  color: white;
  margin-bottom: 5px;
}
</style>
