<template>
  <div>
    <PageConversation />
  </div>
</template>

<script>
import PageConversation from "@/plugins/prepChat/components/PageConversation.vue";
export default {
  components: {
    PageConversation,
  },
};
</script>

<style></style>
