var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('header',[_c('img',{staticStyle:{"width":"50px","height":"50px"},attrs:{"src":_vm.groupImage}}),_c('div',[_c('h2',[_vm._v(_vm._s(this.groupName))]),(_vm.infoPreview)?_c('h3',[_vm._v(" ID: "+_vm._s(_vm.infoPreview.id)+" - Phone: "+_vm._s(_vm.infoPreview.phone)+" ")]):_vm._e()])]),_c('ul',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll",value:({ always: false }),expression:"{ always: false }"}],staticClass:"blockChat",attrs:{"id":"chat"},on:{"v-chat-scroll-top-reached":_vm.loadMore}},_vm._l((_vm.listMessage),function(contentMessage,index){return _c('li',{key:index,class:{
        me:
          (contentMessage.sentBy == _vm.prepUser.uid && !_vm.isThirdView) ||
          (_vm.isThirdView && contentMessage.sentBy != _vm.group.createdBy),
        you:
          (contentMessage.sentBy != _vm.prepUser.uid && !_vm.isThirdView) ||
          (_vm.isThirdView && contentMessage.sentBy == _vm.group.createdBy),
      }},[(contentMessage.sentBy != _vm.prepUser.uid && !_vm.isThirdView)?_c('div',{staticClass:"entete"},[_c('span',{staticClass:"status green"}),_c('h2',[_vm._v(_vm._s(_vm.groupName))]),_c('h3',[_vm._v("("+_vm._s(_vm._f("formatUnix")(contentMessage.sentAt.seconds))+")")])]):_vm._e(),(contentMessage.sentBy == _vm.prepUser.uid && !_vm.isThirdView)?_c('div',{staticClass:"entete"},[_c('h3',[_vm._v(_vm._s(_vm._f("formatUnix")(contentMessage.sentAt.seconds)))]),_c('h2',[_vm._v(_vm._s(_vm.prepUser.fullname))]),_c('span',{staticClass:"status blue"})]):_vm._e(),(_vm.isThirdView)?_c('div',{staticClass:"entete"},[_c('h3',[_vm._v(_vm._s(_vm._f("formatUnix")(contentMessage.sentAt.seconds)))]),_c('h2',[_vm._v(" "+_vm._s(_vm.getUserById(contentMessage.sentBy).displayName))]),_c('span',{staticClass:"status blue"})]):_vm._e(),_c('div',{staticClass:"triangle"}),_c('div',{staticClass:"message"},[(contentMessage.type == 'text')?_c('div',{domProps:{"innerHTML":_vm._s(contentMessage.messageText)}}):_vm._e(),(contentMessage.type == 'image')?_c('div',{staticClass:"message-image"},_vm._l((contentMessage.messageText),function(imageSrc,indexImage){return _c('img',{key:indexImage,attrs:{"src":imageSrc},on:{"click":function($event){return _vm.openPreview(imageSrc)}}})}),0):_vm._e(),(contentMessage.type == 'tag-marking')?_c('div',{staticClass:"message-tag-marking"},[_c('span',[_vm._v("#Hỏi đáp chấm bài")]),_c('p',[_vm._v(" Course: "),_c('a',{attrs:{"href":_vm.tagMarkingLink(contentMessage.messageText),"target":"_blank"}},[_vm._v(_vm._s(_vm.tagMarkingName(contentMessage.messageText)))])]),(_vm.tagMarkingLesson(contentMessage.messageText) != 'undefined')?_c('p',[_vm._v(" Lesson: "+_vm._s(_vm.tagMarkingLesson(contentMessage.messageText))+" ")]):_vm._e(),(
              _vm.tagMarkingTicketID(contentMessage.messageText) != 'undefined'
            )?_c('p',[_vm._v(" Ticket ID: #"+_vm._s(_vm.tagMarkingTicketID(contentMessage.messageText))+" ")]):_vm._e()]):_vm._e()])])}),0),_c('footer',{staticClass:"block-chat-footer"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.group.text),expression:"group.text"}],attrs:{"placeholder":"Nhập nội dung"},domProps:{"value":(_vm.group.text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.handleEventSendMessage(_vm.group, $event)},"input":function($event){if($event.target.composing)return;_vm.$set(_vm.group, "text", $event.target.value)}}}),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.group.contentType),expression:"group.contentType"}],attrs:{"type":"hidden","value":"text"},domProps:{"value":(_vm.group.contentType)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.group, "contentType", $event.target.value)}}}),_c('img',{attrs:{"src":require('../assets/ico_picture.png')},on:{"click":function($event){return _vm.openSelectImage()}}}),_c('input',{ref:"inputfilehidden",staticClass:"input-file-hidden",attrs:{"type":"file","hidden":"","multiple":"","accept":"image/png, image/gif, image/jpeg"},on:{"change":_vm.onFileChange}}),_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.sendMessage(_vm.group)}}},[_vm._v("Gửi")])]),_c('Preview',{attrs:{"url":_vm.urlPreview},on:{"hidePreview":_vm.hidePreview}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }